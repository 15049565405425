<template>
  <v-container class="my-4 py-6">
    <h2>Cantidades por tonelada</h2>
    <v-row
      v-if="editing"
      justify="center"
      class="px-2 mx-4 my-6"
    >
      <v-card
        class="px-4 py-4"
        max-width="800"
      >
        <p class="text-primary">
          {{ textAction }} cantidad por tonelada
        </p>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-row>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                v-model="product.nombre_producto"
                label="Producto"
                :rules="[rules.required]"
                disabled
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="product.unidadMedida"
                label="Unidad de medida"
                :rules="[rules.required]"
                disabled
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.number="product.cantidad_por_tonelada"
                label="Unidades por tonelada"
                type="number"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="actionButton"
          >
            {{ textAction }}
          </v-btn>
          <v-btn
            color="primary"
            @click="cancel"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-row
      v-if="!editing"
      justify="center"
      class="px-2 mx-4 my-6 mx-sm-0"
    >
      <v-col
        cols="12"
        md="10"
        sm="12"
      >
        <v-text-field
          solo
          label="Buscar producto"
          append-icon="mdi-magnify"
          clearable
          v-model="search"
          @keyup="filterByName"
          @click:clear="clearText"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="!editing"
      class="my-4"
      justify="center"
    >
      <v-col>
        <v-row
          v-if="!distributorBasicComplete"
          justify="center"
        >
          <h2>Es necesario dar de alta por lo menos una sucursal y un almacén</h2>
        </v-row>
        <div v-else-if="filteredProducts.length">
          <paginate
            name="products"
            :list="filteredProducts"
            :per="perPage"
            class="pl-0"
          >
            <v-row justify="center">
              <v-col
                v-for="product in paginated('products')" 
                :key="product.id"
                md="4"
              >
                <v-card
                  max-width="300"
                  class="mx-4 my-4"
                >
                  <v-card-text>
                    <p class="display-1 text--primary">
                      {{ product.nombre_producto }}
                    </p>
                    <div>
                      Unidad de medida: {{ product.unidad_medida.dato }}
                    </div>
                    <div v-if="product.idUnit !== null">
                      Cantidad por tonelada: {{ product.cantidad_por_tonelada }}
                    </div>
                    <div v-else>
                      Equivalencia sin definir
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="primary"
                      @click="edit(product)" 
                    >
                      {{ product.idUnit !== null ? "Actualizar" : "Crear"}}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="deleteUnitsPerTon(product)"
                    >
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </paginate>
          <paginate-links
            for="products"
            :async="true"
            :limit="5"
            :show-step-links="true"
            :hide-single-page="true"
            :classes="{
              'ul': 'pagination',
              'li': 'page-item',
              'li > a': 'page-link'
            }"
            @change="onPageChange"
          >
          </paginate-links>
        </div>
        <v-row 
          v-else
          justify="center"
        >
          <h2>No hay productos que mostrar</h2>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiAuth } from '@/api_utils/axios-base'
import { validationMixin } from '@/mixins/formsValidations.js'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  metaInfo: {
    title: 'Unidades por tonelada'
  },
  components: {
    componentLoader: () => import('@/components/ComponentLoader')
  },
  mixins: [validationMixin],
  data() {
    return {
      editing: false,
      toUpdate: false,
      paginate: ['products'],
      perPage: 6,
      responsePage: 1,
      paginateLimit: 1,
      filteredProducts: [],
      search: "",
      allProducts: [],
      products: [],
      product: {
        id: null,
        nombre_producto: null,
        cantidad_por_tonelada: null,
        idUnit: null
      }
    }
  },
  computed: {
    ...mapState('usuario', ['userData']),
    ...mapGetters('usuario', ['distributorBasicComplete']),
    textAction() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
  },
  created() {
    this.loadInitialData()
  },
  methods: {
    ...mapActions(['setLoading']),
    async loadInitialData() {
      await this.getProductos()
      this.filterByName()
    },
    filterByName() {
      const searchLower = this.search.toLowerCase()
      const filter = this.allProducts.filter(product => {
        const productNameLower = product.nombre_producto.toLowerCase()
        if (productNameLower.includes(searchLower)) {
          return product
        }
      })
      this.filteredProducts = filter
    },
    async getProductos() {
      const productResponse = await ApiAuth.get('/api/products/')
      const productResponseData = await productResponse.data
      this.allProducts = productResponseData.results
      for (const product of this.allProducts) {
        await this.getUnitPerTon(product)
      }
    },
    async edit(product) {
      this.editing = true
      this.product.id = product.id
      this.product.nombre_producto = product.nombre_producto
      this.product.unidadMedida = product.unidad_medida.dato
      await this.getUnitPerTon(product)
      this.product.idUnit = product.idUnit
      this.product.cantidad_por_tonelada = product.cantidad_por_tonelada
      // console.log('producto a editar: ', product);
      this.toUpdate = product.idUnit ? true : false
    },
    async createUnitsPerTon(product) {
      try {
        const response = await ApiAuth.post('/crear-unidades-por-tonelada', {
          id_producto: product.id,
          cantidad: product.cantidad_por_tonelada,
          id_autor: this.userData.id
        })
        const responseData = await response.data
        // console.log('Response data crear unidades: ', responseData);
        this.updateUnitsInAllProducts(product.id, responseData.id, product.cantidad_por_tonelada)
        this.editing = false
      } catch (error) {
        console.error('Ocurrio un error al crear unidades por tonelada', error)
      }
    },
    async updateUnitsPerTon(product) {
      try {
        const response = await ApiAuth.put(`/api/unidades-por-tonelada/${product.idUnit}/?id_producto=${product.id}`, {
          cantidad_por_tonelada: product.cantidad_por_tonelada
        })
        this.updateUnitsInAllProducts(product.id, product.idUnit, product.cantidad_por_tonelada)
        this.editing = false
      } catch (error) {
        console.error('Ocurrio un error al actualizar unidades por tonelada', error)
      }
    },
    async deleteUnitsPerTon(product) {
      try {
        this.$store.commit('setLoading', true)
        const response = await ApiAuth.delete(`/api/unidades-por-tonelada/${product.idUnit}/?id_producto=${product.id}`)
      } catch (error) {
        console.error('Ocurrio un error al eliminar unidades por tonelada') 
      } finally {
        this.$store.commit('setLoading', false)
      }
    },
    async getUnitPerTon(product) {
      try {
        const response = await ApiAuth.get(`/api/unidades-por-tonelada/?id_producto=${product.id}`)
        const responseData = await response.data
        // console.log('responseGet unit: ', responseData);
        if (responseData.length > 0) {
          const unit = responseData[0]
          product.idUnit = unit.id
          product.cantidad_por_tonelada = unit.cantidad_por_tonelada
        } else {
          product.idUnit = null
          product.cantidad_por_tonelada = null
        }
      } catch (error) {
        console.error('Ocurrio un error al obtener las unidades del producto', error)
      }
    },
    updateUnitsInAllProducts(idProduct, idUnit, cantidad_por_tonelada) {
      const productSelected = this.allProducts.find(product => product.id === idProduct)
      productSelected.idUnit = idUnit
      productSelected.cantidad_por_tonelada = cantidad_por_tonelada.toFixed(2)
    },
    actionButton() {
      if(!this.$refs.form.validate()) {
        return 
      }
      if (this.toUpdate) {
        this.updateUnitsPerTon(this.product)
      } else {
        this.createUnitsPerTon(this.product)
      }
    },
    cancel() {
      this.editing = false
      this.clear()
    },
    clear() {
      this.product = {
        id: null,
        nombre_producto: null,
        precio_distribuidor: null,
        idUnit: null,
        cantidad_por_tonelada: null
      }
    },
    clearText() {
      this.search = ""
      this.filterByName()
    },
    onPageChange(toPage, fromPage) {
      if (toPage === this.calculatePaginateLimit()) {
        this.responsePage++
        this.getPaginatedProducts(this.responsePage)
      }
    },
    async getPaginatedProducts(page) {
      const productResponse = await ApiAuth.get(`/api/products/?page=${page}`)
      const productResponseData = await productResponse.data

      if (productResponseData.detail) {
        console.log("No se pudieron obtener los producto. ");
      } else {
        const tempProducts = productResponseData.results
        for (const product of tempProducts) {
          await this.getUnitPerTon(product)
        }
        this.allProducts.push(...tempProducts)
        this.filterByName()
      }
    },
    calculatePaginateLimit() {
      return (this.allProducts.length % this.perPage) > 0 
        ? parseInt(this.allProducts.length / this.perPage) + 1
        : parseInt(this.allProducts.length / this.perPage)
    },
  }
}
</script>

<style>

</style>